<script setup>
import { computed } from 'vue';
import CnsCard from '../cns/cns-card.vue';
import CnsNumber from '../cns/cns-number.vue';
import CnsIcon from '../cns/cns-icon.vue';
import CnsDiv from '../cns/cns-div.vue';

const props = defineProps({
  value: { type: [Number, String, Array], default: undefined },
  icon: { type: String, default: undefined },
  label: { type: String, default: '' },
  um: { type: String, default: '' },
  loading: { type: Boolean, default: false },
  variant: { type: String, default: undefined },
  bg: { type: String, default: undefined },
  color: { type: String, default: undefined },
  fill: { type: Boolean, default: false },
  format: { type: Function, default: undefined },
  prefix: { type: String, default: undefined },
  suffix: { type: String, default: undefined },
  decimals: { type: Number, default: 2 },
  animDuration: { type: Number, default: 300 },
  animStartDelay: { type: Number, default: 0 }
});

// props.value can be a Number, a String or an Array composed like [[utc, value]]
const value = computed(() => Array.isArray(props.value) ? props.value[props.value.length - 1]?.[1] : props.value);
const timestamp = computed(() => Array.isArray(props.value) ? props.value[props.value.length - 1]?.[0] : undefined);
const timestampParsed = computed(() => timestamp.value != null ? window.HigJS.num.formatDate(timestamp.value) : undefined);

// follows the same logic of variants
const cardStyle = computed(() => {
  const s = {};
  if (props.color) {
    s.color = props.color;
  }
  if (props.bg) {
    s.borderLeftColor = props.bg;
    if (props.fill) {
      s.background = props.bg;
    }
  }
  return s;
});

// follows the same logic of variants
const iconContainerStyle = computed(() => {
  const s = {};
  if (props.color) { s.color = props.color; }
  if (props.bg) { s.background = props.bg; }
  return s;
});
</script>

<template>
  <cns-card class="cns-data-card" :class="[props.variant, { fill: props.fill }]" no-header no-body :style="cardStyle">
    <div class="d-flex flex-column gap-2 p-3">
      <div class="d-flex justify-content-between">
        <div class="label">{{ props.label }}</div>
        <slot name="actions">
        </slot>
      </div>
      <div class="d-flex align-items-center gap-2">
        <div v-if="props.icon" class="icon-container rounded-circle d-flex align-items-center justify-content-center" :style="iconContainerStyle">
          <slot name="icon">
            <cns-icon class="icon" :type="props.icon + ' fw'" />
          </slot>
        </div>
        <div class="d-flex flex-column align-items-start">
          <div class="d-flex align-items-end">
            <cns-div class="value" :loading="props.loading" placeholder="##">
              <cns-number
                :value="value"
                :title="timestampParsed || ''"
                :format="props.format"
                :prefix="props.prefix"
                :suffix="props.suffix"
                :decimals="props.decimals"
                :anim-duration="props.animDuration"
                :anim-start-delay="props.animStartDelay"
              />
            </cns-div>
            <div class="um">{{ props.um }}</div>
          </div>
        </div>
      </div>
    </div>
  </cns-card>
</template>

<style scoped>
.cns-data-card { border-left: 10px solid; }
.cns-data-card.primary { border-left-color: var(--hig-primary); }
.cns-data-card.secondary { border-left-color: var(--hig-secondary); }
.cns-data-card.success { border-left-color: var(--hig-success); }
.cns-data-card.info { border-left-color: var(--hig-info); }
.cns-data-card.warning { border-left-color: var(--hig-warning); }
.cns-data-card.danger { border-left-color: var(--hig-danger); }
.cns-data-card.light { border-left-color: var(--hig-light); }
.cns-data-card.dark { border-left-color: var(--hig-dark); }

.cns-data-card.primary.fill { background-color: var(--hig-primary); color: var(--hig-primary-text); }
.cns-data-card.secondary.fill { background-color: var(--hig-secondary); color: var(--hig-secondary-text); }
.cns-data-card.success.fill { background-color: var(--hig-success); color: var(--hig-success-text); }
.cns-data-card.info.fill { background-color: var(--hig-info); color: var(--hig-info-text); }
.cns-data-card.warning.fill { background-color: var(--hig-warning); color: var(--hig-warning-text); }
.cns-data-card.danger.fill { background-color: var(--hig-danger); color: var(--hig-danger-text); }
.cns-data-card.light.fill { background-color: var(--hig-light); color: var(--hig-light-text); }
.cns-data-card.dark.fill { background-color: var(--hig-dark); color: var(--hig-dark-text); }

.icon-container {
  width: 3rem;
  height: 3rem;
  flex: 0 0 auto;
  background-color: var(--hig-page-text);
  color: var(--hig-page-bg);
}

.cns-data-card.primary .icon-container { background-color: var(--hig-primary); color: var(--hig-primary-text); }
.cns-data-card.secondary .icon-container { background-color: var(--hig-secondary); color: var(--hig-secondary-text); }
.cns-data-card.success .icon-container { background-color: var(--hig-success); color: var(--hig-success-text); }
.cns-data-card.info .icon-container { background-color: var(--hig-info); color: var(--hig-info-text); }
.cns-data-card.warning .icon-container { background-color: var(--hig-warning); color: var(--hig-warning-text); }
.cns-data-card.danger .icon-container { background-color: var(--hig-danger); color: var(--hig-danger-text); }
.cns-data-card.light .icon-container { background-color: var(--hig-light); color: var(--hig-light-text); }
.cns-data-card.dark .icon-container { background-color: var(--hig-dark); color: var(--hig-dark-text); }

.icon {
  font-size: 1.5rem;
}

.value {
  font-size: 2.2rem;
  line-height: 2.8rem;
}

.label {
  font-size: .9rem;
  line-height: 1rem;
  opacity: .7;
}

.um {
  opacity: .7;
  line-height: 2rem;
}
</style>
