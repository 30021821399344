/* global HigJS */

import './css/hig-bootstrap.css';
import './css/hig-variables.css';

import { ref, watch, inject } from 'vue';
import { getColorComplements, getColorShades } from './libs/colorUtils.mjs';
import ThemeTypeSwitch from './components/theme-type-switch.vue';

const IS_MOBILE_UA = HigJS.browser.isMobile(); // this function checks the user agent to determine if the device is a mobile

let instance = window.higBsPlugInstance;

if (!instance) {
  // require('./css/hig-bootstrap.css');
  // require('./css/hig-variables.css');

  /* ############################################### */
  /* ##               INITIALIZATION              ## */
  /* ############################################### */
  instance = ref({
    theme: {
      primary: window.getComputedStyle(document.documentElement).getPropertyValue('--hig-brand-primary'),
      secondary: window.getComputedStyle(document.documentElement).getPropertyValue('--hig-brand-secondary'),
      light: window.getComputedStyle(document.documentElement).getPropertyValue('--hig-neutral-light-100'),
      dark: window.getComputedStyle(document.documentElement).getPropertyValue('--hig-neutral-dark-900')
    },
    themeType: 'light',
    isXs: false,
    minXs: false,
    maxXs: false,
    isSm: false,
    minSm: false,
    maxSm: false,
    isMd: false,
    minMd: false,
    maxMd: false,
    isLg: false,
    minLg: false,
    maxLg: false,
    isXl: false,
    minXl: false,
    maxXl: false,
    isMobile: false
  });

  const head = document.getElementsByTagName('head')[0];

  const colorSchemeMeta = document.createElement('meta');
  colorSchemeMeta.name = 'color-scheme';
  colorSchemeMeta.content = '';
  head.appendChild(colorSchemeMeta);

  const themeColorMeta = document.createElement('meta');
  themeColorMeta.name = 'theme-color';
  themeColorMeta.content = '';

  const themeColorMetaLight = document.createElement('meta');
  themeColorMetaLight.name = 'theme-color';
  themeColorMetaLight.content = window.getComputedStyle(document.documentElement).getPropertyValue('--hig-neutral-light-100');
  themeColorMetaLight.media = '(prefers-color-scheme: light)';
  head.appendChild(themeColorMetaLight);

  const themeColorMetaDark = document.createElement('meta');
  themeColorMetaDark.name = 'theme-color';
  themeColorMetaDark.content = window.getComputedStyle(document.documentElement).getPropertyValue('--hig-neutral-dark-800');
  themeColorMetaDark.media = '(prefers-color-scheme: dark)';
  head.appendChild(themeColorMetaDark);

  /* ############################################### */
  /* ##              UTILS FUNCTIONS              ## */
  /* ############################################### */
  function applyThemeType (type) {
    switch (type) {
      case 'light':
        document.documentElement.setAttribute('color-scheme', 'light');
        window.localStorage.setItem('hig:lightDarkTheme', 'light');
        colorSchemeMeta.content = 'light';
        themeColorMeta.content = window.getComputedStyle(document.documentElement).getPropertyValue('--hig-neutral-light-100');
        head.appendChild(themeColorMeta);
        themeColorMetaLight.remove();
        themeColorMetaDark.remove();
        break;
      case 'dark':
        document.documentElement.setAttribute('color-scheme', 'dark');
        window.localStorage.setItem('hig:lightDarkTheme', 'dark');
        colorSchemeMeta.content = 'dark';
        themeColorMeta.content = window.getComputedStyle(document.documentElement).getPropertyValue('--hig-neutral-dark-800');
        head.appendChild(themeColorMeta);
        themeColorMetaLight.remove();
        themeColorMetaDark.remove();
        break;
      default:
        document.documentElement.removeAttribute('color-scheme');
        window.localStorage.setItem('hig:lightDarkTheme', 'auto');
        colorSchemeMeta.content = '';
        themeColorMeta.content = '';
        themeColorMeta.remove();
        head.appendChild(themeColorMetaLight);
        head.appendChild(themeColorMetaDark);
        break;
    }
  }

  let prevAppliedTheme = {};
  function applyTheme (theme) {
    const { primary, secondary, light, dark, ...otherValues } = theme;

    let themeToApply = {};

    if (primary) {
      const primaryVersions = getColorComplements(primary);
      themeToApply['--hig-brand-primary'] = primaryVersions.color;
      themeToApply['--hig-brand-primary-rgb'] = primaryVersions.rgb;
      themeToApply['--hig-brand-primary-accent'] = primaryVersions.accent;
      themeToApply['--hig-brand-primary-dim'] = primaryVersions.dim;
      themeToApply['--hig-brand-primary-text'] = primaryVersions.text;
    }

    if (secondary) {
      const secondaryVersions = getColorComplements(secondary);
      themeToApply['--hig-brand-secondary'] = secondaryVersions.color;
      themeToApply['--hig-brand-secondary-rgb'] = secondaryVersions.rgb;
      themeToApply['--hig-brand-secondary-accent'] = secondaryVersions.accent;
      themeToApply['--hig-brand-secondary-dim'] = secondaryVersions.dim;
      themeToApply['--hig-brand-secondary-text'] = secondaryVersions.text;
    }

    if (light) {
      const lightVersions = getColorShades(light, { amounts: [0, -4, -8, -15.5, -21.5, -28.5, -37.5, -48, -58] });

      themeToApply['--hig-neutral-light-100'] = lightVersions[0].hex;
      themeToApply['--hig-neutral-light-200'] = lightVersions[1].hex;
      themeToApply['--hig-neutral-light-300'] = lightVersions[2].hex;
      themeToApply['--hig-neutral-light-400'] = lightVersions[3].hex;
      themeToApply['--hig-neutral-light-500'] = lightVersions[4].hex;
      themeToApply['--hig-neutral-light-600'] = lightVersions[5].hex;
      themeToApply['--hig-neutral-light-700'] = lightVersions[6].hex;
      themeToApply['--hig-neutral-light-800'] = lightVersions[7].hex;
      themeToApply['--hig-neutral-light-900'] = lightVersions[8].hex;
      themeToApply['--hig-neutral-light-100-rgb'] = lightVersions[0].rgb;
      themeToApply['--hig-neutral-light-200-rgb'] = lightVersions[1].rgb;
      themeToApply['--hig-neutral-light-300-rgb'] = lightVersions[2].rgb;
      themeToApply['--hig-neutral-light-400-rgb'] = lightVersions[3].rgb;
      themeToApply['--hig-neutral-light-500-rgb'] = lightVersions[4].rgb;
      themeToApply['--hig-neutral-light-600-rgb'] = lightVersions[5].rgb;
      themeToApply['--hig-neutral-light-700-rgb'] = lightVersions[6].rgb;
      themeToApply['--hig-neutral-light-800-rgb'] = lightVersions[7].rgb;
      themeToApply['--hig-neutral-light-900-rgb'] = lightVersions[8].rgb;
    }

    if (dark) {
      const darkVersions = getColorShades(dark, { amounts: [55.5, 46, 36, 26.5, 17.5, 10.5, 5, 0, -7.5] });

      themeToApply['--hig-neutral-dark-100'] = darkVersions[0].hex;
      themeToApply['--hig-neutral-dark-200'] = darkVersions[1].hex;
      themeToApply['--hig-neutral-dark-300'] = darkVersions[2].hex;
      themeToApply['--hig-neutral-dark-400'] = darkVersions[3].hex;
      themeToApply['--hig-neutral-dark-500'] = darkVersions[4].hex;
      themeToApply['--hig-neutral-dark-600'] = darkVersions[5].hex;
      themeToApply['--hig-neutral-dark-700'] = darkVersions[6].hex;
      themeToApply['--hig-neutral-dark-800'] = darkVersions[7].hex;
      themeToApply['--hig-neutral-dark-900'] = darkVersions[8].hex;
      themeToApply['--hig-neutral-dark-100-rgb'] = darkVersions[0].rgb;
      themeToApply['--hig-neutral-dark-200-rgb'] = darkVersions[1].rgb;
      themeToApply['--hig-neutral-dark-300-rgb'] = darkVersions[2].rgb;
      themeToApply['--hig-neutral-dark-400-rgb'] = darkVersions[3].rgb;
      themeToApply['--hig-neutral-dark-500-rgb'] = darkVersions[4].rgb;
      themeToApply['--hig-neutral-dark-600-rgb'] = darkVersions[5].rgb;
      themeToApply['--hig-neutral-dark-700-rgb'] = darkVersions[6].rgb;
      themeToApply['--hig-neutral-dark-800-rgb'] = darkVersions[7].rgb;
      themeToApply['--hig-neutral-dark-900-rgb'] = darkVersions[8].rgb;
    }

    themeToApply = { ...themeToApply, ...otherValues };

    for (const key in prevAppliedTheme) {
      document.documentElement.style.removeProperty(key);
    }

    for (const key in themeToApply) {
      document.documentElement.style.setProperty(key, themeToApply[key]);
    }
    prevAppliedTheme = themeToApply;

    themeColorMetaLight.content = window.getComputedStyle(document.documentElement).getPropertyValue('--hig-neutral-light-100');
    themeColorMetaDark.content = window.getComputedStyle(document.documentElement).getPropertyValue('--hig-neutral-dark-800');
    applyThemeType(instance.value.themeType);
  }

  let checkScreenSizeWait = false;
  function checkScreenSize () {
    if (checkScreenSizeWait) { return; }

    checkScreenSizeWait = true;
    setTimeout(() => {
      checkScreenSizeWait = false;
      instance.value.isXs = false;
      instance.value.minXs = false;
      instance.value.maxXs = false;
      instance.value.isSm = false;
      instance.value.minSm = false;
      instance.value.maxSm = false;
      instance.value.isMd = false;
      instance.value.minMd = false;
      instance.value.maxMd = false;
      instance.value.isLg = false;
      instance.value.minLg = false;
      instance.value.maxLg = false;
      instance.value.isXl = false;
      instance.value.minXl = false;
      instance.value.maxXl = false;
      instance.value.isXxl = false;
      instance.value.minXxl = false;
      instance.value.maxXxl = false;
      if (window.innerWidth < 576) { // xs
        // IS
        instance.value.isXs = true;
        // MIN
        instance.value.minXs = true;
        // MAX
        instance.value.maxXs = true;
        instance.value.maxSm = true;
        instance.value.maxMd = true;
        instance.value.maxLg = true;
        instance.value.maxXl = true;
        instance.value.maxXxl = true;
      } else if (window.innerWidth < 768) { // sm
        // IS
        instance.value.isSm = true;
        // MIN
        instance.value.minXs = true;
        instance.value.minSm = true;
        // MAX
        instance.value.maxSm = true;
        instance.value.maxMd = true;
        instance.value.maxLg = true;
        instance.value.maxXl = true;
        instance.value.maxXxl = true;
      } else if (window.innerWidth < 992) { // md
        // IS
        instance.value.isMd = true;
        // MIN
        instance.value.minXs = true;
        instance.value.minSm = true;
        instance.value.minMd = true;
        // MAX
        instance.value.maxMd = true;
        instance.value.maxLg = true;
        instance.value.maxXl = true;
        instance.value.maxXxl = true;
      } else if (window.innerWidth < 1200) { // lg
        // IS
        instance.value.isLg = true;
        // MIN
        instance.value.minXs = true;
        instance.value.minSm = true;
        instance.value.minMd = true;
        instance.value.minLg = true;
        // MAX
        instance.value.maxLg = true;
        instance.value.maxXl = true;
        instance.value.maxXxl = true;
      } else if (window.innerWidth < 1400) { // xl
        // IS
        instance.value.isXl = true;
        // MIN
        instance.value.minXs = true;
        instance.value.minSm = true;
        instance.value.minMd = true;
        instance.value.minLg = true;
        instance.value.minXl = true;
        // MAX
        instance.value.maxXl = true;
        instance.value.maxXxl = true;
      } else { // xxl
        // IS
        instance.value.isXxl = true;
        // MIN
        instance.value.minXs = true;
        instance.value.minSm = true;
        instance.value.minMd = true;
        instance.value.minLg = true;
        instance.value.minXl = true;
        instance.value.minXxl = true;
        // MAX
        instance.value.maxXxl = true;
      }

      instance.value.isMobile = IS_MOBILE_UA || instance.value.maxMd;
    }, 250);
  }

  /* ############################################### */
  /* ##                  WATCHERS                 ## */
  /* ############################################### */
  watch(() => instance.value.theme, (newVal) => { applyTheme(newVal); }, { deep: true });
  watch(() => instance.value.themeType, (newVal) => { applyThemeType(newVal); });
  window.addEventListener('resize', checkScreenSize);
  watch(() => instance.value.isMobile, () => {
    if (instance.value.isMobile) {
      document.documentElement.setAttribute('is-mobile', '');
    } else {
      document.documentElement.removeAttribute('is-mobile', '');
    }
  }, { immediate: true });

  /* ############################################### */
  /* ##               STARTUP VALUES              ## */
  /* ############################################### */
  instance.value.themeType = window.localStorage.getItem('hig:lightDarkTheme') || 'light';

  // *** WHEN USING THE AUTO THEME FORCE THE LIGHT ONE *** //
  instance.value.themeType = instance.value.themeType === 'auto' ? 'light' : instance.value.themeType;
  // *** WHEN USING THE AUTO THEME FORCE THE LIGHT ONE *** //

  if (['light', 'dark', 'auto'].indexOf(instance.value.themeType) === -1) { instance.value.themeType = 'light'; }
  checkScreenSize();

  Object.defineProperty(window, 'higBsPlugInstance', { value: instance });
}

const higBootstrap = {};
Object.defineProperty(higBootstrap, 'theme', {
  get () { return instance.value.theme; },
  set (newVal) { instance.value.theme = newVal; },
  enumerable: true
});
Object.defineProperty(higBootstrap, 'themeType', {
  get () { return instance.value.themeType; },
  set (newVal) { instance.value.themeType = newVal; },
  enumerable: true
});
Object.defineProperty(higBootstrap, 'isXs', { get () { return instance.value.isXs; }, enumerable: true });
Object.defineProperty(higBootstrap, 'minXs', { get () { return instance.value.minXs; }, enumerable: true });
Object.defineProperty(higBootstrap, 'maxXs', { get () { return instance.value.maxXs; }, enumerable: true });
Object.defineProperty(higBootstrap, 'isSm', { get () { return instance.value.isSm; }, enumerable: true });
Object.defineProperty(higBootstrap, 'minSm', { get () { return instance.value.minSm; }, enumerable: true });
Object.defineProperty(higBootstrap, 'maxSm', { get () { return instance.value.maxSm; }, enumerable: true });
Object.defineProperty(higBootstrap, 'isMd', { get () { return instance.value.isMd; }, enumerable: true });
Object.defineProperty(higBootstrap, 'minMd', { get () { return instance.value.minMd; }, enumerable: true });
Object.defineProperty(higBootstrap, 'maxMd', { get () { return instance.value.maxMd; }, enumerable: true });
Object.defineProperty(higBootstrap, 'isLg', { get () { return instance.value.isLg; }, enumerable: true });
Object.defineProperty(higBootstrap, 'minLg', { get () { return instance.value.minLg; }, enumerable: true });
Object.defineProperty(higBootstrap, 'maxLg', { get () { return instance.value.maxLg; }, enumerable: true });
Object.defineProperty(higBootstrap, 'isXl', { get () { return instance.value.isXl; }, enumerable: true });
Object.defineProperty(higBootstrap, 'minXl', { get () { return instance.value.minXl; }, enumerable: true });
Object.defineProperty(higBootstrap, 'maxXl', { get () { return instance.value.maxXl; }, enumerable: true });

Object.defineProperty(higBootstrap, 'isMobile', { get () { return instance.value.isMobile; }, enumerable: true });

export default {
  install: function (app) {
    app.component('theme-type-switch', ThemeTypeSwitch);
    app.provide('$higBs', higBootstrap);
    app.config.globalProperties.$higBs = higBootstrap;

    if (app.config.globalProperties.$store) {
      app.config.globalProperties.$store.$higBs = higBootstrap;
    }
  }
};

function useHigBootstrap () {
  return inject('$higBs');
}

export {
  useHigBootstrap
};
