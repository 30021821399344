<script setup>
import { ref, inject, getCurrentInstance, watch, onBeforeUnmount } from 'vue';

const emit = defineEmits(['active']);

const props = defineProps({
  title: { type: String, default: undefined },
  color: { type: String, default: undefined },
  icon: { type: String, default: undefined }
});

const id = getCurrentInstance().uid;
const active = ref(false);

const setTab = inject('__cnsCard_setTab');
const remTab = inject('__cnsCard_remTab');

watch(() => [props.title, props.color, props.icon], () => {
  setTab({
    id,
    title: props.title,
    color: props.color,
    icon: props.icon,
    get active () { return active.value; },
    set active (newVal) { active.value = newVal; }
  });
}, { immediate: true });

onBeforeUnmount(() => { remTab(id); });

watch(
  active,
  () => {
    emit('active', active.value);
  }
);
</script>

<template>
  <slot v-if="active"/>
</template>
