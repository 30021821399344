<script setup lang="ts">
import { computed, inject, ref, useSlots, watch, onBeforeUnmount, getCurrentInstance, nextTick } from 'vue';
import { Grid, GridOptions, ColDef, DomLayoutType } from 'ag-grid-community';
import { Sortable } from 'sortablejs-vue3';
// import * as FileSaver from 'file-saver';
// import * as XLSX from 'xlsx';
// import { DateTime } from 'luxon';
import _ from 'lodash';

import { Fop } from '../../../libs/fop-utils/index.browser.mjs';
import type { FilterOrderPaginateFilter, FilterOrderPaginateOrder } from '../../../libs/fop-utils/libs/types.js';
import { vModel } from '../../../libs/vue-utils/index.mjs';

import CnsFilterInput from '../cns-filter-input.vue';
import CnsModal from '../cns-modal.vue';
import CnsIcon from '../cns-icon.vue';
import CnsCard from '../cns-card.vue';
import CnsButton from '../cns-button.vue';

import type { CnsTableCol } from './cns-table-types.js';
import { getCnsTableColFilter, FILTER_TYPE_AG_TO_HIG, FILTER_TYPE_HIG_TO_AG } from './cns-table-filters.js';
import { getCustomCellRenderer } from './custom-cell-renderer.js';
import { getCustomSelectCellRenderer } from './custom-select-cell-renderer.js';
import { getCustomHeaderRenderer } from './custom-header-renderer.js';
import { getCustomLoadingOverlay } from './custom-loading-overlay.js';
import { getCustomNoRowsOverlay } from './custom-no-rows-overlay.js';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './cns-table-style.css';

const provides = getCurrentInstance()?.appContext.provides; // This is needed so that the components in the slots can access to all the provided values

const HEAD_SLOT_SUFFIX = '-head';

interface AgGridColDef extends ColDef {
  colId: string;
  field?: string;
  headerName?: string;
  comparator?: (a: any, b: any) => number;
  valueFormatter?: (a: any) => any;
  filter?: string;
  filterParams?: any;
  sortable?: boolean;
  lockPinned?: boolean;
  flex?: number;
  width?: number;
  minWidth?: number;
  maxWidth?: number;
  suppressMovable?: boolean;
  pinned?: boolean | 'left' | 'right';
  hide?: boolean;
  cellRenderer?: any;
  cellRendererParams?: any;
  headerComponentParams?: any;
  autoHeaderHeight?: boolean;
}

interface AgGridOptions extends GridOptions {
  columnDefs: AgGridColDef[];
  domLayout: DomLayoutType;
}

interface Props {
  /* base options */
  cols?: CnsTableCol[];
  data?: any[] | ((fop: Fop) => any[]) | ((fop: Fop) => Promise<any[]>);
  loading?: boolean;
  rowsId?: string | ((row: any) => string);
  selectedRows?: string[];
  isRowSelectable?: ((row: any) => boolean);
  rowsHeight?: number;
  rowsSelection?: boolean | 'single' | 'multiple';
  colsPinning?: boolean;
  // rowsPinning: boolean; // Not supported yet

  /* filters and sorting */
  filter?: FilterOrderPaginateFilter[];
  sort?: FilterOrderPaginateOrder[];
  fop?: Fop;

  /* buttons */
  noActionsBar?: boolean;
  noResetFiltersAndSort?: boolean;
  noExport?: boolean;
  // clientSideExport: boolean;
  noCustomizeCols?: boolean;

  /* data fetch options */
  fetchPageSize?: number;

  /* style */
  compact?: boolean;
  striped?: boolean;
  hover?: boolean;
  bordered?: boolean;
  autoHeight?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  cols: () => [],
  data: () => [],
  loading: false,
  rowsId: 'id',
  rowsSelection: false,
  colsPinning: false,
  // rowsPinning: false,
  noActionsBar: false,
  noResetFiltersAndSort: false,
  noExport: true,
  // clientSideExport: false,
  noCustomizeCols: false,
  fetchPageSize: 200,
  compact: false,
  striped: true,
  hover: true,
  bordered: true,
  autoHeight: false
});
const emit = defineEmits(['row-click', 'cell-click', 'export', 'scroll-to-bottom', 'update:filter', 'update:sort', 'update:selected-rows']);
const slots = useSlots();

const $edw:{ [key:string]: any } = inject('$edw');
const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

const pinnedCols = ref({});
const hiddenCols = ref({});
const sortedCols = ref([]);
const globalSearchValue = ref('');
const customColsModal = ref();
const creatingXlsxReport = ref(false);
const gridDiv = ref();

let grid = null;
let gridCreateTo = null;
let gridApi = null;
let gridColApi = null;
let refreshingData = false;
let purgingData = false;
const gridRowIds = new Set();

const noRows = ref(false);
const loadingError = ref(null);
const fetchLoading = ref(false);
const loading = computed(() => props.loading || fetchLoading.value);

const rowsHeight = computed(() => props.rowsHeight ?? (props.compact ? 30 : 42));

const selectedRows = vModel({
  get: () => new Set((props.selectedRows ?? []).map(String)),
  set: (newVal) => emit('update:selected-rows', [...newVal]),
  onSourceUpdate: () => updateSelectedRows()
});

const sortModel = vModel({
  get: () => {
    if (Array.isArray(props.sort) && props.sort.length > 0) {
      return props.sort.map((s) => ({
        colId: s.field,
        sort: s.dir === 1 ? 'asc' : 'desc'
      }));
    }
    return [];
  },
  set: (newVal) => {
    emit('update:sort', newVal.map((sort) => ({ field: sort.colId, dir: sort.sort === 'asc' ? 1 : -1 })));
  },
  onSourceUpdate: () => updateSortModel()
});

const filterModel = vModel({
  get: () => {
    const res = {};
    if (Array.isArray(props.filter) && props.filter.length > 0) {
      props.filter.forEach((filter) => {
        if (!res[filter.field]) { res[filter.field] = { operator: 'AND', conditions: [] }; }
        let operator = 'AND';
        let filterConditions = [];
        if (filter.or) {
          operator = 'OR';
          filterConditions = filter.or;
        } else if (filter.and) {
          filterConditions = filter.and;
        } else {
          filterConditions = [filter];
        }

        res[filter.field] = {
          operator,
          conditions: filterConditions.map((condition) => ({ filterType: 'text', type: FILTER_TYPE_HIG_TO_AG[condition.op] || condition.op, filter: condition.val }))
        };
      });
    }
    return res;
  },
  set: (newVal) => {
    const filters = [];
    for (const colName in newVal) {
      const filter = newVal[colName];
      if (!filter.conditions) { // Single filter
        filters.push({
          field: colName,
          op: FILTER_TYPE_AG_TO_HIG[filter.type],
          val: filter.filter
        });
      } else if (filter.operator === 'OR') { // multiple filters with or
        filters.push({
          or: filter.conditions.map((condition) => ({
            field: colName,
            op: FILTER_TYPE_AG_TO_HIG[condition.type],
            val: condition.filter
          }))
        });
      } else if (filter.operator === 'AND') { // multiple filters with and
        filters.push({
          and: filter.conditions.map((condition) => ({
            field: colName,
            op: FILTER_TYPE_AG_TO_HIG[condition.type],
            val: condition.filter
          }))
        });
      }
    }

    emit('update:filter', filters);
  },
  onSourceUpdate: () => updateFilterModel()
});

const fop = computed(() => {
  let fop = new Fop(props.fop);

  if (globalSearchValue.value) {
    const searchCols = cols.value.filter((col) => col.searchable);
    fop = fop.filter.or(searchCols.map(col => ({
      field: col.searchField || col.field || col.name,
      op: '%', // Like
      val: globalSearchValue.value
    })));
  }

  // Filter
  if (filterModel.value) {
    for (const colName in filterModel.value) {
      const col = props.cols.find((col) => col.name === colName);
      if (!col) { continue; }

      const filter = filterModel.value[colName];
      const conditions = (filter?.conditions || [filter]).map((condition) => {
        const field = col.filterField || col.field || col.name;
        if (condition.filterType === 'date' && condition.type === 'inRange') {
          return {
            and: [
              { field, op: '>=', val: condition.filter.start },
              { field, op: '<=', val: condition.filter.stop }
            ]
          };
        }
        return {
          field,
          op: FILTER_TYPE_AG_TO_HIG[condition.type],
          val: condition.filter
        };
      });
      fop = fop.filter(filter?.operator === 'OR' ? { or: conditions } : conditions); // Notice: works only with one or two conditions at once
    }
  }

  // Sort
  if (sortModel.value) {
    for (const s of sortModel.value) {
      const col = props.cols.find((col) => col.name === s.colId);
      if (!col) { continue; }

      const field = col.sortField || col.field || col.name;
      fop = fop.order(field, s.sort === 'asc' ? 1 : -1);
    }
  }

  return fop.serialize();
});

const cols = computed<CnsTableCol[]>(() => {
  return props.cols?.map((col) => {
    const res = {
      ...col,
      movable: col.movable ?? true,
      label: col.label ?? $edw[col.name],
      hidden: col.hidden ?? false,
      searchable: col.searchable ?? true
    };

    return res;
  });
});

const getId = computed(() => typeof props.rowsId === 'function' ? props.rowsId : (row) => _.get(row, props.rowsId));
const dataIsFetch = ref(false);
watch(() => props.data, () => { // Trick to not trigger computed variables that depend on dataIsFetch
  if (dataIsFetch.value !== (typeof props.data === 'function')) {
    dataIsFetch.value = typeof props.data === 'function';
  }

  if (dataIsFetch.value) { purgeCache(); } // Clear the cached values if the fetch function changed
}, { immediate: true });

const data = computed(() => {
  if (dataIsFetch.value) { return undefined; }

  const data = props.data as any[];
  let globalSearchFop;
  if (globalSearchValue.value) {
    const searchCols = cols.value.filter((col) => col.searchable);
    globalSearchFop = new Fop().filter.or(searchCols.map(col => ({
      field: col.searchField || col.field || col.name,
      op: '%', // Like
      val: globalSearchValue.value
    })));
  }

  const res = [];
  for (let i = 0; i < data.length; i++) {
    if (globalSearchFop && !globalSearchFop.doesFilterPass(data[i])) { continue; }
    res.push({ ...data[i], id: getId.value(data[i]) || i });
  }

  return res;
});

const fetch = async (fop) => {
  if (!dataIsFetch.value) { return []; }

  const res = await (props.data as ((fop: Fop) => Promise<any[]>))(fop);
  if (Array.isArray(res)) {
    return res.map((row) => {
      const parsedRow = { ...row, id: getId.value(row) };
      if (parsedRow.id == null) { throw new Error('Missing row id: ' + JSON.stringify(row)); }
      return parsedRow;
    });
  }
  return res;
};

const globalSearchShow = computed(() => {
  if (!props.noActionsBar && cols.value.length > 0 && !cols.value.some((col) => col.searchable)) {
    console.warn('[cns-table]: No searchable columns, hiding the search box');
    return false;
  }
  return true;
});

watch([hiddenCols, pinnedCols], () => {
  if (!gridColApi) { return; }

  const colStates = gridColApi.getColumnState();
  const newStates = [];
  for (const s of colStates) {
    newStates.push({
      colId: s.colId,
      pinned: pinnedCols.value[s.colId] || null,
      hide: hiddenCols.value[s.colId]
    });
  }
  if (props.rowsSelection) {
    newStates.push({ colId: '__checkbox', pinned: 'left' });
  }
  gridColApi.applyColumnState({ state: newStates });
  onColsSorted({ type: 'columnMoved', finished: true, column: {} });

  updateColumnsSize();
}, { deep: true });

const agGridColumns = ref<AgGridColDef[]>([]);
watch(() => [cols.value, slots], () => {
  const newSortCols = [];
  for (const col of cols.value) {
    pinnedCols.value[col.name] ??= col.pinned ?? false;
    hiddenCols.value[col.name] ??= col.hidden ?? false;

    if (!sortedCols.value.some((c) => c.id === col.name)) {
      newSortCols.push(col);
    }
  }

  // Add new columns to sortedCols to the end
  sortedCols.value = sortedCols.value.concat(newSortCols.map((col) => ({ id: col.name, name: col.label, movable: col.movable })));
  // Remove deleted columns from sortedCols
  sortedCols.value = sortedCols.value.filter((sortCol) => cols.value.some((col) => col.name === sortCol.id));

  const columnDefs:AgGridColDef[] = cols.value.map((col) => {
    const filter = getCnsTableColFilter(col, provides);
    const comparator = (a, b, nodeA, nodeB) => {
      a = _.get(nodeA.data, col.sortField || col.field || col.name);
      b = _.get(nodeB.data, col.sortField || col.field || col.name);

      if (col.comparator) {
        return col.comparator(a, b);
      } else {
        return collator.compare(a, b);
      }
    };

    return {
      colId: col.name,
      field: col.field || col.name,
      headerName: col.label,
      comparator: !dataIsFetch.value ? comparator : undefined,
      valueFormatter: col.format ? (el) => col.format(el?.value) : undefined,
      filter: filter?.filter,
      filterParams: filter?.filterParams,
      sortable: col.sortable,
      lockPinned: props.colsPinning ? col.lockPinned : true,
      flex: col.width ? undefined : (col.flex || 1),
      width: col.width,
      minWidth: col.minWidth,
      maxWidth: col.maxWidth,
      suppressSizeToFit: col.width != null,
      suppressMovable: col.movable === false,

      // Pinned and hidden columns cannot be changed from props once rendered
      pinned: pinnedCols.value[col.name],
      hide: hiddenCols.value[col.name],

      // Custom cell renderer
      cellRenderer: getCustomCellRenderer(provides),
      cellRendererParams: { getSlot: () => slots[col.name], getCol: () => col },

      // Custom header renderer
      headerComponentParams: { getSlot: () => slots[col.name + HEAD_SLOT_SUFFIX], getCol: () => col },
      autoHeaderHeight: true
    } as AgGridColDef;
  });

  if (props.rowsSelection) {
    columnDefs.unshift({
      colId: '__checkbox',
      headerName: '',
      width: 35,
      minWidth: 35,
      maxWidth: 35,
      lockPosition: 'left',
      resizable: false,
      suppressMovable: true,
      suppressSizeToFit: true,
      showDisabledCheckboxes: true,
      cellRenderer: getCustomSelectCellRenderer(provides)
    } as AgGridColDef);
  }

  agGridColumns.value = columnDefs;
}, { immediate: true, deep: true });
watch(agGridColumns, () => { updateColumns(); });

function agGridOptionsOnGridReady (params) {
  gridApi = params.api;
  gridColApi = params.columnApi;

  gridApi.addEventListener('cellClicked', (e) => {
    if (e.data && e.column.colId !== '__checkbox') {
      emit('row-click', { index: e.rowIndex, row: e.data });
      emit('cell-click', { index: e.rowIndex, row: e.data, val: e.value, col: e.column?.colDef?.cellRendererParams?.getCol?.() });
    }
    if (e.node) { gridApi.refreshCells({ force: true, rowNodes: [e.node] }); };
  });

  gridApi.addEventListener('rowSelected', (e) => {
    if (e.node.isSelected()) {
      if (!selectedRows.value.has(e.data.id)) {
        selectedRows.value = new Set([...selectedRows.value, e.data.id]);
      }
    } else if (selectedRows.value.has(e.data.id)) {
      selectedRows.value = new Set([...selectedRows.value].filter((id) => id !== e.data.id));
    }
  });

  gridApi.addEventListener('columnVisible', (e) => { if (e.column?.colId) { hiddenCols.value[e.column.colId] = !e.visible; } });
  gridApi.addEventListener('columnPinned', (e) => { if (e.column?.colId) { pinnedCols.value[e.column.colId] = e.pinned; } });
  gridApi.addEventListener('columnMoved', onColsSorted);
  gridApi.addEventListener('gridColumnsChanged', onColsSorted);
  gridApi.addEventListener('gridSizeChanged', _.debounce(updateColumnsSize, 100));

  gridApi.addEventListener('filterChanged', () => {
    if (!gridApi) { return; }
    filterModel.value = gridApi.getFilterModel();
  });

  gridApi.addEventListener('sortChanged', () => {
    if (!gridApi) { return; }
    sortModel.value = gridApi.sortController.getSortModel();
  });

  // emit an event when the grid is scrolled to the bottom
  gridApi.addEventListener('bodyScroll', _.throttle((e) => {
    if (e.direction === 'vertical' && e.top >= e.bottom - 200) {
      emit('scroll-to-bottom', fop.value);
    }
  }), 100);

  // Set initial client-side data
  if (!dataIsFetch.value) {
    gridApi.setRowData(data.value);
  }

  updateColumns();
  updateSortModel();
  updateFilterModel();
  updateSelectedRows();
  updateOverlay();
}

let lastFop = null;
let lastResult = null;
async function agGridOptionsGetRows ({ startRow, endRow, failCallback, successCallback }) {
  const pageSize = endRow - startRow;
  const pageNum = Math.floor(startRow / pageSize);
  const _fop = new Fop(fop.value).paginate(pageNum, pageSize);

  const noLoading = refreshingData; // do not show the loader if it's only refreshing the data
  if (refreshingData || purgingData) {
    refreshingData = false;
    purgingData = false;
    gridRowIds.clear();
  } else if (_fop.equals(lastFop)) { // Avoid reloading data that's already loaded, dunno why but in some cases ag-grid requests the same data multiple times
    successCallback(...lastResult);
    return;
  }

  if (pageNum === 0 && !noLoading) { fetchLoading.value = true; }

  loadingError.value = null;

  let curPageData;
  try {
    curPageData = await fetch(_fop.serialize());
  } catch (err) {
    console.error(err);
    loadingError.value = $edw.translateError(err);
    noRows.value = true;
    failCallback();
    return;
  }

  if (Array.isArray(curPageData) && curPageData.length > 0) { // Keep track of row ids
    for (const row of curPageData) { gridRowIds.add(row.id); }
  }

  const dataIsFinished = !curPageData || curPageData.length < pageSize;
  const lastRow = dataIsFinished ? gridRowIds.size : null;

  lastFop = _fop;
  lastResult = [curPageData || [], lastRow];
  successCallback(curPageData || [], lastRow);

  updateSelectedRows();

  fetchLoading.value = false;
  if (pageNum === 0 && dataIsFinished && (!Array.isArray(curPageData) || curPageData.length === 0)) {
    noRows.value = true;
  } else {
    noRows.value = false;
  }
}

const agGridOptions = computed<AgGridOptions>(() => {
  const res:AgGridOptions = {
    columnDefs: [],
    onGridReady: agGridOptionsOnGridReady,
    rowSelection: props.rowsSelection === false ? undefined : (props.rowsSelection === true ? 'multiple' : props.rowsSelection),
    suppressRowClickSelection: true,
    alwaysMultiSort: false,
    rowHeight: rowsHeight.value,
    domLayout: props.autoHeight ? 'autoHeight' : 'normal',
    getRowId: (row) => row.data.id,
    isRowSelectable: props.rowsSelection !== false && props.isRowSelectable
      ? (row) => {
          if (row.data?.id == null) { return false; }
          return props.isRowSelectable(row.data);
        }
      : undefined,
    components: {
      agColumnHeader: getCustomHeaderRenderer(provides)
    },
    defaultColDef: {
      resizable: true
    },
    isExternalFilterPresent: () => fop.value?.filter?.length > 0 || globalSearchValue.value !== '',
    doesExternalFilterPass: (row) => row.data && (new Fop(fop.value)).apply([row.data]).length > 0,
    loadingOverlayComponent: getCustomLoadingOverlay(provides),
    noRowsOverlayComponent: getCustomNoRowsOverlay(provides),
    noRowsOverlayComponentParams: { getError: () => loadingError.value }
  };

  if (dataIsFetch.value) { // Infinite scrolling
    res.rowModelType = 'infinite';
    res.cacheBlockSize = props.fetchPageSize;
    res.datasource = { getRows: _.debounce(agGridOptionsGetRows, 100) };
  } else {
    res.rowModelType = 'clientSide';
  }

  return res;
});

watch(agGridOptions, () => {
  clearTimeout(gridCreateTo);
  gridCreateTo = setTimeout(() => {
    destroyGrid();
    if (gridDiv.value) {
      grid = new Grid(gridDiv.value, { ...agGridOptions.value } as AgGridOptions);
    }
  }, 10);
}, { immediate: true });

watch(data, () => {
  nextTick(() => {
    if (!gridApi) { return; }
    gridApi.setRowData(data.value);
    noRows.value = data.value.length === 0;
    updateSelectedRows();
    // gridApi.redrawRows(); // TODO: Is this needed?
  });
});

watch([loading, noRows], () => {
  updateOverlay();
});

watch(globalSearchValue, () => {
  if (!gridApi) { return; }
  purgeCache();
});

watch(filterModel, () => {
  if (!gridApi) { return; }
  purgeCache();
});

function updateColumns () {
  if (!gridApi) { return; }
  gridApi.setColumnDefs(agGridColumns.value);
  // gridApi.refreshCells({ force: true }); // TODO: Is this needed?

  updateColumnsSize();
}

function updateColumnsSize () {
  if (!gridColApi) { return; }
  gridColApi.autoSizeColumns(props.cols.map(c => c.name), false);
  if (gridColApi.getAllDisplayedColumns().reduce((acc, c) => acc + c.actualWidth, 0) < (gridDiv.value?.offsetWidth ?? 0)) {
    gridApi.sizeColumnsToFit();
  }
};

function updateSelectedRows () {
  if (!gridApi) { return; }
  const rowNodesToSelect = [];
  const rowNodesToDeselect = [];
  gridApi.forEachNode((rowNode) => {
    if (!rowNode?.data?.id) { return; }
    if (selectedRows.value.has(rowNode.data.id)) {
      if (!rowNode.isSelected()) {
        rowNodesToSelect.push(rowNode);
      }
    } else if (rowNode.isSelected()) {
      rowNodesToDeselect.push(rowNode);
    }
  });
  if (rowNodesToSelect.length > 0) { gridApi.setNodesSelected({ nodes: rowNodesToSelect, newValue: true }); }
  if (rowNodesToDeselect.length > 0) { gridApi.setNodesSelected({ nodes: rowNodesToDeselect, newValue: false }); }
}

function updateSortModel () {
  if (!gridColApi) { return; }
  gridColApi.applyColumnState({
    state: sortModel.value.map((sort, i) => ({
      colId: sort.colId,
      sort: sort.sort,
      sortIndex: i
    })),
    defaultState: { sort: null }
  });
}

function updateFilterModel () {
  if (!gridApi) { return; }
  gridApi.setFilterModel(filterModel.value);
}

function updateOverlay () {
  if (!gridApi) { return; }

  if (loading.value) {
    gridApi.showLoadingOverlay();
  } else if (noRows.value) {
    gridApi.showNoRowsOverlay();
  } else {
    gridApi.hideOverlay();
  }
}

function purgeCache () {
  if (!gridApi || !dataIsFetch.value) { return; }
  purgingData = true;
  gridApi.purgeInfiniteCache();
}

function refreshCache () {
  if (!gridApi || !dataIsFetch.value) { return; }
  refreshingData = true;
  gridApi.refreshInfiniteCache();
}

function resetFilters () {
  if (!gridApi) { return; }
  globalSearchValue.value = '';
  gridApi.setFilterModel({});
}

function resetSort () {
  if (!gridColApi) { return; }
  gridColApi.applyColumnState({ defaultState: { sort: null } });
}

function resetColumnsCustomization () {
  pinnedCols.value = {};
  for (const col of props.cols) {
    hiddenCols.value[col.name] = col.hidden || false;
    pinnedCols.value[col.name] = col.pinned || false;
    sortedCols.value = props.cols.map((col) => ({ id: col.name, name: col.label, movable: col.movable }));
  }
  customColsModal.value.close();
  onColsSorted(null, true);
}

// Removes __checkbox col and empty headerName ones
const sortedColsFilterMap = (cols) => cols.filter((col) => col.colId !== '__checkbox' && col.colDef.headerName).map((col) => ({ id: col.colId, name: col.colDef.headerName, movable: !col.colDef.suppressMovable }));
function onColsSorted (e = null, forceColReorder = false) {
  if (!e) { // Reset
    if (forceColReorder && gridColApi) {
      gridColApi.moveColumns(cols.value.map((col) => col.name), props.rowsSelection ? 1 : 0);
    }
  } else if (e.type === 'columnMoved') { // Column moved by header drag
    if (e.finished && e.column && gridColApi) {
      sortedCols.value = sortedColsFilterMap(gridColApi.getAllGridColumns());
    }
  } else if (e.newIndex != null && e.oldIndex != null) { // Column moved from modal
    const b = sortedCols.value[e.newIndex].id;
    const a = sortedCols.value[e.oldIndex].id;
    const cols = gridColApi.getAllGridColumns().map((col) => col.colId);

    gridColApi.moveColumnByIndex(cols.findIndex(c => c === a), cols.findIndex(c => c === b));
  }
}

function pinColumn (colName) {
  pinnedCols.value[colName] = pinnedCols.value[colName] ? null : 'left';
}

function openCustomColsModal () {
  onColsSorted({ type: 'columnMoved', finished: true, column: {} });
  customColsModal.value.open();
}

function getSelectedRows () {
  if (!gridApi) { return; }
  return [...selectedRows.value];
}

function onExportRequest () {
  // if (props.clientSideExport) {
  //   exportAsXlsx(new Fop(fop.value).paginate(false));
  // } else {
  emit('export', fop.value);
  // }
}

// async function exportAsXlsx (fop) {
//   creatingXlsxReport.value = true;
//   let aoo:any[] = dataIsFetch.value ? await fetch(fop.serialize()) : data.value;
//   const fileName = 'Export_' + DateTime.now().toFormat('yyyy_LL_dd__HH_mm');

//   const gridVisibleCols = gridColApi.getAllDisplayedColumns();
//   if (props.rowsSelection) { gridVisibleCols.shift(); }

//   const colsFieldMap = {};
//   for (const c of props.cols) {
//     colsFieldMap[c.name] = c.field || c.name;
//   }
//   const headers = gridVisibleCols.map(c => c.colDef.headerName);
//   const dataHeaders = gridVisibleCols.map(c => c.colId);

//   aoo = aoo.map((row) => {
//     const newRow = {};
//     for (const c of gridVisibleCols) {
//       newRow[c.colId] = String(_.get(row, colsFieldMap[c.colId]));
//     }
//     return newRow;
//   });

//   const worksheet = XLSX.utils.json_to_sheet(aoo, { header: dataHeaders });
//   XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' }); // Headers
//   worksheet['!cols'] = fitToColumn(aoo.map(row => Object.values(row)));
//   function fitToColumn (aoa) { // get maximum character of each column
//     return aoa[0]?.map((a, i) => ({ wch: Math.max(...aoa.map(a2 => a2[i] ? a2[i].toString().length : 0)) })) || 10;
//   }

//   const workbook = XLSX.utils.book_new();
//   XLSX.utils.book_append_sheet(workbook, worksheet, fileName);

//   const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
//   const fileData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
//   FileSaver.saveAs(fileData, fileName + '.xlsx');
//   creatingXlsxReport.value = false;
// }

onBeforeUnmount(() => { destroyGrid(); });

function destroyGrid () {
  gridApi = undefined;
  gridColApi = undefined;
  if (grid) {
    try {
      if (grid) { grid.destroy(); }
    } catch {
      // TODO: Sometimes there are some problems here but dunno how to solve them
      // so the grid is not correctly destroyed and some components will remain active
      // this is really bad as it causes memory leaks and unexpected behaviors
    }
  }

  if (gridDiv.value) {
    gridDiv.value.innerHTML = '';
  }
}

defineExpose({ dataReset: purgeCache, purgeCache, refreshCache, getSelectedRows, resetSort, resetFilters });
</script>

<template> <!-- @keydown.esc="customColsModal?.close()" -->
  <div class="cns-table d-flex flex-column pt-sm-1 w-100 h-100">
    <!-- Columns modal -->
    <cns-modal ref="customColsModal" :title="$edw.customColumns" size="lg" centered>
      <Sortable
        :list="sortedCols"
        class="ag-sort-pin-cols-modal"
        style="flex: 1; max-height: 70vh; overflow-y: scroll;"
        easing="cubic-bezier(1, 0, 0, 1)"
        item-key="id"
        :options="{
          draggable: '.draggable',
          handle: '.handle',
          animation: 150,
          ghostClass: 'ghost',
          dragClass: 'drag',
          scroll: true,
          forceFallback: true,
          scrollSensitivity: 50,
          scrollSpeed: 10,
          bubbleScroll: true,
        }"
        @sort="onColsSorted"
      >
        <template #item="{ element: c }">
          <cns-card no-header no-body :class="`${c.movable ? 'draggable' : ''} mb-2 p-1 ps-2 me-1`" :key="c.id">
            <div class="d-flex flex-row justify-content-between p-2">
              <p class="m-0 p-0" style="font-size: 1.1em"><cns-icon v-if="c.movable" type="grip-dots-vertical solid" class="handle ms-1 me-3"/>{{ c.name }}</p>
              <div class="d-flex flex-row gap-3">
                <cns-icon :type="`thumbtack ${pinnedCols[c.id] ? 'solid' : ''}`" class="m-auto cursor-pointer" @click="pinColumn(c.id)"/>
                <cns-icon :type="`${hiddenCols[c.id] ? 'eye-slash solid' : 'eye'} fw`" class="m-auto cursor-pointer" @click="hiddenCols[c.id] = !hiddenCols[c.id]"/>
              </div>
            </div>
          </cns-card>
        </template>
      </Sortable>
      <div class="mt-4 d-flex flex-row justify-content-between">
        <cns-button :text="$edw.resetToDefault" variant="secondary" icon="rotate-left" @click="resetColumnsCustomization"/>
        <cns-button :text="$edw.done" variant="primary" @click="customColsModal.close"/>
      </div>
    </cns-modal>

    <!-- Top bar -->
    <div v-if="!props.noActionsBar" class="d-flex flex-row justify-content-between mb-2 w-100">
      <div class="ms-sm-1">
        <cns-filter-input v-if="globalSearchShow" v-model="globalSearchValue" :placeholder="$edw.search + '...'" style="width: 200px; line-height: 1em;" :debounce="1000"/>
      </div>
      <div class="d-flex align-content-center gap-4">
        <!-- TODO preset filters from select <cns-filter-input v-model="tobBarFilter" :placeholder="$edw.filter + '...'" icon="bars-filter" style="width: 200px;" lazy/> -->
        <div class="d-flex align-content-center gap-1 pe-1 ag-tob-bar-icons">
          <cns-button v-if="!props.noResetFiltersAndSort" variant="link" :title="$edw.resetFiltersAndSort" icon="filter-circle-xmark sm fw" @click="resetFilters(); resetSort();"/>
          <cns-button v-if="!props.noExport" variant="link" :title="$edw.export" :icon="`${creatingXlsxReport ? 'spinner-third spin' : 'file-export'} sm fw`" @click="onExportRequest"/>
          <cns-button v-if="!props.noCustomizeCols" variant="link" :title="$edw.customizeTable" icon="columns-3 sm fw" @click="openCustomColsModal()"/>
          <slot name="actions"/>
        </div>
      </div>
    </div>

    <!-- Grid container -->
    <div ref="gridDiv" class="ag-grid ag-theme-alpine-dark flex-fill w-100 align-self-stretch" :class="{ 'striped': props.striped, 'hover': props.hover, 'bordered': props.bordered }"/>
  </div>
</template>

<style scoped>
.draggable .handle {
  cursor: grab;
}

.draggable.drag, .dragable.sortable-chosen {
  cursor: grabbing;
}

.ag-grid:deep(.ag-layout-auto-height .ag-center-cols-viewport),
.ag-grid:deep(.ag-layout-auto-height .ag-center-cols-container) {
  min-height: 0 !important; /* rmv the default min-height, so the auto-height mode has an actual auto height */
}
</style>
